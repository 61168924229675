a {
  text-decoration: none;
}

.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}


.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}
div.waves{
  width: 100%;
  bottom: 0;
  /*height: 100%;*/
  position: absolute;
  z-index: 99;
}
.tutorial-link{
  font-family: sans-serif;
  text-align: center;
  color: #f3f3f3;
}
.tutorial-link h2{
  background: linear-gradient(90deg, #00b4db 0%, #224488 50%, #0083b0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  
}
.tutorial-link a{
  text-decoration: none;
  background: linear-gradient(90deg, #00b4db 0%, #224488 50%, #0083b0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  
}

svg{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  animation-name: move-left;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.spacer{
  height: 100px;
  width: 100%;
}

@keyframes example {
  0%   {
    left:0px;
    top:0px;}
  
  50%  {
    left:-2000px;
    top:200px;}
  
  100% {
    left:0px; 
    top:0px;}
}

